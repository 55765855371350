import { Button, Form, Input } from 'antd';
import { FORGOT_PASSWORD_RULES as RULES } from 'constants/formValidations';
import Link from 'gatsby-link';
import React, { useContext, useEffect, useState } from 'react';
import { callPostApi } from '../api/axios';
import '../assets/style/authStyles.css';
import AuthLayout from '../components/auth/authLayout';
import MixpanelContext from '../services/tracking';
import { toast, toastTypes } from '../utils/toast';

export default function ForgotPassword() {
  const mixpanel = useContext(MixpanelContext);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  async function handleForgotPassword() {
    setLoading(true);
    mixpanel.track('ForgotPassword', {
      email,
    });
    try {
      // call apis with the entered values
      const { data } = await callPostApi('/v1/api/forgot-password', {
        user_id: email,
      });
      toast(toastTypes.SUCCESS, data.message);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Forgot Password Page',
    });
  }, [mixpanel]);

  return (
    <AuthLayout>
      <div className="content_height d-flex align-items-center justify-content-center">
        <div className="auth_card">
          <h3 className="header_text">Forgot Password?</h3>
          <p className="description_text">
            Please provide the email address you used to register with Fairmatic
          </p>
          <Form
            name="forgot_password_form"
            initialValues={{
              remember: true,
            }}
            onFinish={() => handleForgotPassword()}
            autoComplete="off"
          >
            <Form.Item name="email" rules={RULES.email}>
              <Input
                size="large"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Item>

            <div style={{ margin: '0 auto', width: '250px' }}>
              <Form.Item>
                <Button
                  disabled={loading || !email}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div className="footer_height d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-cener">
          <Link to="/login" className="navigation_link">
            Sign In to Fairmatic
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}
